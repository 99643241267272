import {Appointment} from '@allaihealth/serverless-common';
import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {MatRipple, MatRippleModule} from "@angular/material/core";
import {DatePipe} from "@angular/common";

/** Navigateion at the type of the apppointment page */
@Component({
	selector: 'app-appointment-nav',
	standalone: true,
	imports: [
		RouterLink,
		MatIconModule,
		MatRippleModule,
		DatePipe
	],
	templateUrl: './appointment-nav.component.html',
	styleUrl: './appointment-nav.component.scss'
})
export class AppointmentNavComponent {
	/** The previous appointment if it exists */
	@Input() previous?:Appointment;

	/** The next appointment if it exists */
	@Input() next?:Appointment;

	/** Get the router link to the previous appointment */
	get prevLink() {
		return this.previous ? ["../", this.previous.id] : undefined;
	}

	/** Get the router link to the next appointment */
	get nextLink() {
		return this.next ? ["../", this.next.id] : undefined;
	}
}
