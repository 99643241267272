<ul>
	@for (segment of summary.segments; track segment) {
		<li>
			<span class="clickable" (click)="onSelected(segment)">{{ segment.content }}</span>

			@if (segment.subItems) {
				<ul>
					@for (subSegment of segment.subItems; track subSegment) {
						<li>
							<span class="clickable" (click)="onSelected(subSegment)">{{ subSegment.content }}</span>
						</li>
					}
				</ul>
			}
		</li>

	}
</ul>
