import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";

/** Simple service that makes interaction with {@link MatSnackBar} easier */
@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	constructor(private snackbar:MatSnackBar) {
	}

	/**
	 * Show an error message to the user with an "ok" button
	 * @param message the message to show
	 * @param error a potential error to show to the user
	 */
	error(message:string, error?:any) {
		if(error) {
			this.snackbar.open(`${message}: ${SnackbarService.getErrorMessage(error)}`, "Ok");
		} else {
			this.snackbar.open(message, "Ok");
		}
	}

	/**
	 * Show a message to the user in the snack bar for a short amount of time
	 * @param message the message to show
	 */
	message(message:string) {
		this.snackbar.open(message, undefined, {duration: 5000});
	}

	/** Get the error string from an error object */
	public static getErrorMessage(error:any) {
		let message:string = 'unknown';

		//parse based on what type of object it is
		if(error instanceof HttpErrorResponse) {
			message = error.statusText ?? error.message;
		} else if(error instanceof Error) {
			message = error.message;
		} else if(typeof error === "string") {
			message = error;
		}

		return message;
	}
}
