/** Various utility functions for the application */
export abstract class Utils {

	/** Attempt to get the duration of an audio blob. This won't always work due to the way web audio works */
	static getAudioBlobDuration(blob:Blob) {
		return new Promise<number>((resolve, reject) => {
			const audio = new Audio(URL.createObjectURL(blob));

			audio.addEventListener("loadedmetadata", event => {
				if(isFinite(audio.duration) && audio.duration > 0) {
					resolve(audio.duration);
				}

				reject("Failed to get duration");
			}, {once: true});

			audio.addEventListener("error", event => {
				reject(event);
			});
		})
	}
}
