@if (devices && devices.length > 0) {
	<mat-form-field>
		<mat-label>Input Device to Use</mat-label>
		<mat-select [(ngModel)]="selectedDevice">
			@for (device of devices; track device) {
				<mat-option [value]="device">{{ device.label }}</mat-option>
			}
		</mat-select>
	</mat-form-field>
}
