import {Component, Input, ViewChild} from '@angular/core';
import {RecordingInfo, SummarySection, Transcription} from "scribe-poc-shared";
import {TranscriptComponent} from "./transcript/transcript.component";
import {SummaryComponent} from "./summary/summary.component";
import {MatTabGroup, MatTabsModule} from "@angular/material/tabs";
import {MatCardModule} from "@angular/material/card";
import {MatTooltip} from "@angular/material/tooltip";

/** The transcription page that shows the summary and transcript of an audio recording */
@Component({
	selector: 'app-transcription',
	standalone: true,
	imports: [
		TranscriptComponent,
		SummaryComponent,
		MatCardModule,
		MatTabsModule,
		MatTooltip
	],
	templateUrl: './transcription.component.html',
	styleUrl: './transcription.component.scss'
})
export class TranscriptionComponent {
	/** Tabs to show across the top of the transcript */
	readonly tabs:SummaryTab[] = [
		{name: "HISTORY_OF_PRESENT_ILLNESS", label: "HPI", tooltip: "History of Present Illness"},
		{name: "REVIEW_OF_SYSTEMS", label: "ROS", tooltip: "Review of Systems"},
		{name: "PAST_MEDICAL_HISTORY", label: "PmHx", tooltip: "Past Medical History"},
		{name: "ASSESSMENT", label: "Assmt", tooltip: "Assessment"},
		{name: "PLAN", label: "Plan", tooltip: "Plan"}
	];

	/** The transcription loaded from the server */
	private _transcription:Transcription;

	/** The recording to show the transcription information for */
	@Input()
	recording:RecordingInfo;

	/** The summary look-ups to show */
	summaries:Map<string, SummarySection>;

	/** The evidence that was selected by the user to highlight in the transcript */
	evidence:string[];

	/** The tab group handling whether we are looking at the summary or transcript */
	@ViewChild(MatTabGroup)
	tabGroup:MatTabGroup;

	/** The transcription loaded from the server */
	@Input()
	set transcription(value:Transcription) {
		this._transcription = value;

		this.summaries = new Map(value.summarySections.map(value => [value.name, value]));
	}

	/** The transcription loaded from the server */
	get transcription() {
		return this._transcription;
	}

	/** When the user selects a summary item, set the transcript items that should be highlighted */
	onNewEvidence(evidence:string[]) {
		this.evidence = evidence;

		if(this.tabGroup) {
			this.tabGroup.selectedIndex = this.tabs.length;
		}
	}

	/** Get the elapsed time of the transcription process in the backend */
	getElapsedTime() {
		return this.recording.created && this.recording.transcribed ?
			Math.round((this.recording.transcribed - this.recording.created) / 60000) : "n/a";
	}
}

/** Tab to show in the transcription summary */
interface SummaryTab {
	/** Internal name of the summary */
	name:string;

	/** The label to show in the tab */
	label:string;

	/** Tool tip that shows the whole name of the tab */
	tooltip:string;
}
