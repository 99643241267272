"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mime = void 0;
/** Helper class for parsing mime types and file extensions for audio files */
class Mime {
    /** Normalize a mime type from a string provided by the browser (e.g. "audio/ogg; codecs=opus", "audio/wav")*/
    static normalizeMime(mimeType) {
        let value = mimeType.trim().toLowerCase();
        const index = value.indexOf(";");
        if (index > 0) {
            value = value.substring(0, index);
        }
        return value;
    }
    /** Convert a mime type to it's common file extension */
    static mimeToFileExt(mimeType) {
        const ext = this.SupportedMimesAndExtensions.get(mimeType);
        if (!ext) {
            throw new Error(`Mime type "${mimeType}" is not supported`);
        }
        return ext;
    }
    /** Is the given mime type supported by this application? */
    static isSupportedMime(mimeType) {
        return this.SupportedMimesAndExtensions.has(mimeType);
    }
    /** All supported mime types for the application */
    static get supportedMimes() {
        return this.PreferredMimes.map(value => value.name);
    }
    /** All supported file extensions for the application */
    static get supportedExtensions() {
        return this.PreferredMimes.map(value => value.ext);
    }
}
exports.Mime = Mime;
_a = Mime;
/** All supported mime types for the application */
Mime.PreferredMimes = [
    { name: "audio/webm", ext: "webm" },
    { name: "audio/ogg", ext: "ogg" },
    { name: "audio/wav", ext: "wav" },
    { name: "audio/flac", ext: "flac" },
    { name: "audio/mpeg", ext: "mp3" },
    { name: "audio/mp4", ext: "mp4" },
];
/** All supported mime types keyed of the mime type */
Mime.SupportedMimesAndExtensions = new Map(_a.PreferredMimes.map(value => [value.name, value.ext]));
