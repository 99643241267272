import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PageLoaderService} from "./services/page-loader.service";
import {HeaderComponent} from "./components/header/header.component";

/** Main component of the app */
@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, HeaderComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	title = 'scribe-poc';

	constructor(private pageLoaderService:PageLoaderService) {
	}

	ngOnInit() {
		//startup and start listening for page loads
		this.pageLoaderService.startup();
	}
}
