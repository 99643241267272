import { Component } from '@angular/core';
import {MatIcon} from "@angular/material/icon";

/** Indicator at the top of the appointment page that shows that the recording is being processed */
@Component({
  selector: 'app-waiting-header',
  standalone: true,
	imports: [
		MatIcon
	],
  templateUrl: './waiting-header.component.html',
  styleUrl: './waiting-header.component.scss'
})
export class WaitingHeaderComponent {

}
