<h1>Create a New Recording</h1>

<p>Use the options below to upload a new recording to the scribe:</p>

<div class="recording-section">

	@switch (state) {
		@case (RecordingStates.Waiting) {
			<app-device-picker></app-device-picker>

			<div class="recording-controls">
				<button mat-flat-button color="primary" (click)="startMic()">
					<mat-icon>mic</mat-icon>
					Start Recording!
				</button>

				<input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="fileInputChange($event)"
				       [accept]="mimesAccepted"/>

				<button mat-flat-button (click)="fileInput.click()">
					<mat-icon>upload</mat-icon>
					Upload file
				</button>
			</div>
		}
		@case (RecordingStates.Recording) {
			<div class="recording-controls">
				<button mat-flat-button color="primary" (click)="stopMic()">
					<mat-icon>stop</mat-icon>
					Stop Recording
				</button>
			</div>
		}
		@case (RecordingStates.Done) {
			<p>Audio file was recorded</p>

			<app-device-picker></app-device-picker>

			<div class="recording-controls">
				<button mat-flat-button color="primary" (click)="startMic()">
					<mat-icon>play_arrow</mat-icon>
					Resume recording {{durationText}}
				</button>

				<button mat-stroked-button color="primary" (click)="upload()">
					<mat-icon>upload</mat-icon>
					End appointment
				</button>
			</div>
		}
		@case (RecordingStates.Unsupported) {
			<mat-error>Unfortunately, it doesn't seem like your browser doesn't support audio input.</mat-error>
		}
		@case (RecordingStates.NoDevices) {
			<mat-error>No microphones found on this device.</mat-error>
		}
		@case (RecordingStates.MicDeclined) {
			<mat-error>It looks like you declined mic access, please refresh the page and try again.</mat-error>
		}
		@case (RecordingStates.Error) {
			<mat-error>It looks like there was an error, check log for details.</mat-error>
		}
	}

</div>
