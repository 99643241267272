@if (data.title) {
	<h2 mat-dialog-title>{{ data.title }}</h2>
}

<mat-dialog-content class="mat-typography">
	{{data.message}}
</mat-dialog-content>

<mat-dialog-actions align="end">
	@if(data.no) {
		<button mat-button [mat-dialog-close]="false">{{ data.no }}</button>
	}

	@if(data.yes) {
		<button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ data.yes }}</button>
	}
</mat-dialog-actions>
