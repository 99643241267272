export const environment = {
	/** Name of this environment */
	name: "dev",

	/** URL for the API REST server */
	apiUrl: "https://opdxqn3nbe.execute-api.us-east-1.amazonaws.com/prod",

	/** Login Cognito client ID */
	cognitoClientId: 'f4h9h1kqvv90ankstb3ido04d',

	/** Login Cognito pool ID */
	cognitoUserPoolId: 'us-east-1_By7X1BGHt',

	/** Time before a page loading spinner appears */
	pageLoaderTime: 300,

	/** The main portal url */
	mainUrl: "https://dev.allaihealth.dev"
};
