import {Routes} from '@angular/router';
import {AppointmentsComponent} from "./pages/appointments/appointments.component";
import {AppointmentsService} from "./services/appointments.service";
import {LoginComponent} from "./pages/login/login.component";
import {ListComponent} from "./pages/list/list.component";
import {AppointmentComponent} from "./pages/appointment/appointment.component";
import {SessionService} from "./services/session.service";

export const routes:Routes = [
	{
		path: "login",
		title: "Login",
		component: LoginComponent
	},
	{
		path: "appointments",
		canActivate: [SessionService.canActivateLoggedIn],
		resolve: {appointments: AppointmentsService.resolveAppointments},
		children: [
			{
				path: "",
				title: "Today's Appointments",
				component: AppointmentsComponent
			},
			{
				path: ":id",
				title: "Appointment",
				component: AppointmentComponent,
				resolve: {appointment: AppointmentsService.resolveAppointment},
			}
		]
	},
	{
		path: "list",
		title: "Recording List",
		component: ListComponent,
		canActivate: [SessionService.canActivateLoggedIn],
		resolve: {appointments: ListComponent.resolve}
	},
	{path: '**', redirectTo: 'appointments'}
];
