import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {AppointmentResponse, AppointmentsResponse, RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {HttpClient} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SelectionModel} from "@angular/cdk/collections";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {firstValueFrom, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Appointment, Patient, User} from '@allaihealth/serverless-common';
import {StatePillComponent} from "../../components/state-pill/state-pill.component";
import {YesNoData, YesNoDialogComponent} from "../../components/yes-no-dialog/yes-no-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PageLoaderService} from "../../services/page-loader.service";
import {AppointmentsService} from "../../services/appointments.service";

/** Display a list of recordings in the database */
@Component({
	selector: 'app-list',
	standalone: true,
	imports: [
		MatTableModule,
		MatFormFieldModule,
		MatSortModule,
		MatInputModule,
		DatePipe,
		MatCheckboxModule,
		MatIconModule,
		MatButtonModule,
		StatePillComponent
	],
	templateUrl: './list.component.html',
	styleUrl: './list.component.scss'
})
export class ListComponent implements AfterViewInit, OnInit {
	readonly TranscriptionState = TranscriptionState;

	/** Columns in the table */
	readonly displayedColumns:string[] = ['selected', 'time', 'patient', 'provider', 'state', 'elapsed'];

	/** Data source for the table */
	readonly dataSource:MatTableDataSource<ListRow> = new MatTableDataSource<ListRow>();

	/** Items selected in the table */
	selection:SelectionModel<ListRow> = new SelectionModel(true, []);

	/** Header sort functions */
	@ViewChild(MatSort) sort:MatSort;

	/** Table controller */
	@ViewChild(MatTable) table:MatTable<ListRow>;

	constructor(private http:HttpClient,
	            private dialog:MatDialog,
	            private loader:PageLoaderService,
	            private router:Router,
	            private route:ActivatedRoute,
	            private appointmentsService:AppointmentsService) {
	}

	ngOnInit() {
		//get the recordings from route resolver
		const appointments:AppointmentsResponse = this.route.snapshot.data["appointments"];

		//create maps for easy lookup
		const appointmentMap:Map<string, Appointment> = new Map(appointments.appointments.map(value => [value.id, value]));
		const patientMap:Map<string, Patient> = new Map(appointments.patients.map(value => [value.id, value]));
		const providerMap:Map<string, User> = new Map(appointments.providers.map(value => [value.id, value]));

		//set the data for the list
		this.dataSource.data = appointments.recordings.map<ListRow>(recording => {
			const appointment = appointmentMap.get(recording.appointmentId);
			const patient = patientMap.get(appointment.id_patient);
			const provider = providerMap.get(appointment.id_provider);

			//set the date since dates can't be serialized in json
			appointment.appointment_date = new Date(appointment.appointment_date);

			const elapsed = recording.created && recording.transcribed ?
				Math.round((recording.transcribed - recording.created) / 60000) : undefined;

			return {
				appointmentId: recording.appointmentId,
				time: appointment.appointment_date,
				patient: `${patient.first_name} ${patient.last_name}`,
				provider:`${provider.first_name} ${provider.last_name}`,
				state: recording.state,
				elapsed
			};
		}).sort((a, b) => b.time.getTime() - a.time.getTime());

		this.selection.clear();
		this.table?.renderRows();
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
		this.table?.renderRows();
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	toggleAllRows() {
		if(this.isAllSelected) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** Apply a filter from the search bar */
	applyFilter(event:Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if(this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/** Open a recording that was clicked on */
	goto(row:ListRow) {
		this.router.navigate(['/', 'appointments', row.appointmentId]);
	}

	/** Get the elapsed time it took to transcribe a recording */
	getElapsedTime(row:RecordingInfo) {
		return row.created && row.transcribed ? Math.round((row.transcribed - row.created) / 60000) : "";
	}

	/** Delete a series of selected recordings */
	async onDelete() {
		const ref = this.dialog.open<YesNoDialogComponent, YesNoData, boolean>(
			YesNoDialogComponent, {
				data: {
					message: "Are you sure you want to delete these recording(s)? There is no going back.",
					disableClose: true
				}
			});

		//wait for a result
		const value = await firstValueFrom(ref.afterClosed());
		if(value === true) {
			//setup the delete and show a loader
			const deleteRequest = firstValueFrom(this.http.delete(`${environment.apiUrl}/delete`,
				{body: this.selection.selected.map(value => value.appointmentId)}));
			this.loader.showAndListen(deleteRequest, "Deleting...");

			await deleteRequest;

			const data = this.dataSource.data;
			for(const toDelete of this.selection.selected) {
				const index = data.indexOf(toDelete);
				data.splice(index, 1);
			}

			this.selection.clear();
			this.dataSource.data = data;
			this.table.renderRows();

			this.appointmentsService.clear();
		}
	}

	/** Whether the number of selected elements matches the total number of rows. */
	get isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Resolver to load recordings from the database */
	static resolve():Observable<AppointmentResponse> {
		const http = inject(HttpClient);

		//get it from the server
		return http.get<AppointmentResponse>(`${environment.apiUrl}/list`);
	}
}

/** List row to show in the table */
interface ListRow {
	/** ID of the appointment */
	appointmentId:string;

	/** Time of the appointment */
	time:Date;

	/** The name of the patient */
	patient:string;

	/** The name of the provider */
	provider:string;

	/** The state of the transcription */
	state:TranscriptionState;

	/** The elapsed time of the transcription in minutes */
	elapsed:number;
}
