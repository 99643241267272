import {Component, Input, OnInit} from '@angular/core';
import {TranscriptSegment} from "scribe-poc-shared";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import * as changeCase from "change-case";

/** Show the transcript of the conversation */
@Component({
	selector: 'app-transcript',
	standalone: true,
	imports: [
		MatTableModule
	],
	templateUrl: './transcript.component.html',
	styleUrl: './transcript.component.scss'
})
export class TranscriptComponent implements OnInit {
	/** The columns displayed on the page */
	readonly displayedColumns:string[] = ['participant', 'content'];

	/** The data source for the table */
	readonly dataSource:MatTableDataSource<TranscriptSegment> = new MatTableDataSource();

	/** Each row of the transcript to show */
	@Input() transcript:TranscriptSegment[];

	/** Evidence set to highlight on the page */
	private _evidence:Set<string>;

	ngOnInit():void {
		this.dataSource.data = this.transcript;
	}

	/** Convert a participant's name into something more human-readable */
	convertName(participant:string) {
		const participantName = changeCase.capitalCase(participant);
		return participantName.replace(" 0", "");
	}

	/** Is this row of the transcript selected? */
	isSelected(row:TranscriptSegment) {
		return this._evidence && this._evidence.has(row.id);
	}

	/** Set the transcript items that should be highlighted */
	@Input()
	set evidence(value:string[]) {
		this._evidence = new Set<string>(value);
	}
}
