import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {RecordingService} from "../../../../services/recording.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {Subscription} from "rxjs";

/** Picker drop down for audio input devices */
@Component({
	selector: 'app-device-picker',
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		FormsModule
	],
	templateUrl: './device-picker.component.html',
	styleUrl: './device-picker.component.scss'
})
export class DevicePickerComponent implements OnInit, OnDestroy {
	/** Loaded audio input devices from the browser */
	devices:MediaDeviceInfo[];

	/** Subscription for updated devices */
	private subscription:Subscription;

	constructor(private recordingService:RecordingService) {
	}

	ngOnInit() {
		this.devices = this.recordingService.devices;

		//listen for changes
		this.subscription = this.recordingService.onDeviceChangesEvent.subscribe((devices) => {
			this.devices = devices;
		})
	}

	ngOnDestroy():void {
		this.subscription?.unsubscribe();
	}

	/** The device selected by the user for recording */
	get selectedDevice() {
		return this.recordingService.selectedDevice;
	}

	/** The device selected by the user for recording */
	set selectedDevice(value:MediaDeviceInfo) {
		this.recordingService.selectedDevice = value;
	}
}
