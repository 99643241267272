<div class="controls">
	<mat-form-field>
		<mat-label>Search today's appointments</mat-label>
		<input matInput (keyup)="applyFilter($event)">
	</mat-form-field>
</div>

<table mat-table [dataSource]="dataSource">
	<ng-container matColumnDef="time">
		<td mat-cell *matCellDef="let row">{{ row.appointmentTime | date : 'shortTime' }}</td>
	</ng-container>

	<ng-container matColumnDef="patient">
		<td mat-cell *matCellDef="let row">
			<div class="name">{{ row.firstName }} {{ row.lastName }}</div>
			<div class="dob">{{ row.dob | date : 'MM/dd/yyyy' }}</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="info">
		<td mat-cell *matCellDef="let row">
			<app-state-pill [state]="row.state"></app-state-pill>

			@if (row.appointmentType) {
				<div>{{ row.appointmentType }}</div>
			}
		</td>
	</ng-container>

	<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goto(row)"
	    [class.current]="isCurrent(row)"></tr>
</table>

<div class="end-today">
	End of today's appointments
</div>
