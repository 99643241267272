/** Timer that can be paused and started to get a full time */
export class Stopwatch {
	/** Increments of time that has been timed */
	private increments:Increment[] = [];

	/** The current time increment being worked on */
	private currentIncrement:Increment;

	/** Start the timer */
	start() {
		this.currentIncrement = {
			start: new Date().getTime()
		}
	}

	/** Stop the timer */
	stop() {
		if(this.currentIncrement) {
			this.currentIncrement.stop = new Date().getTime();
			this.increments.push(this.currentIncrement);
			this.currentIncrement = null;
		}
	}

	/** Get the total duration of the timer */
	get duration() {
		let total = this.currentIncrement ? new Date().getTime() - this.currentIncrement.start : 0;
		for(const increment of this.increments) {
			total += increment.stop - increment.start;
		}
		return total;
	}
}

/** Increments of time that has been timed */
interface Increment {
	/** Start time in epoch ms */
	start:number;

	/** Stop time in epoch ms */
	stop?:number;
}
