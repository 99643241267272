import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SummarySection, SummarySegment} from "scribe-poc-shared";

/** Show the scribed summary from the transcription of the recording */
@Component({
	selector: 'app-summary',
	standalone: true,
	imports: [],
	templateUrl: './summary.component.html',
	styleUrl: './summary.component.scss'
})
export class SummaryComponent {
	/** The scribed summary to show */
	@Input()
	summary:SummarySection;

	/** When a summary item is clicked, display the evidence in the transcript */
	@Output()
	readonly evidenceClicked:EventEmitter<string[]> = new EventEmitter<string[]>();

	/** When a list item is selected dispatch an event */
	onSelected(subSegment:SummarySegment) {
		this.evidenceClicked.next(subSegment.evidence);
	}
}
