@if ((next || previous) && !isRecording) {
	<app-appointment-nav [next]="next" [previous]="previous"></app-appointment-nav>
}

@if (isRecording) {
	<app-recording-indicator [recording]="recordingComponent"></app-recording-indicator>
}

@if (state == TranscriptionState.Transcription) {
	<app-waiting-header></app-waiting-header>
}

<div class="patient-info">
	<div class="top">
		<a mat-icon-button routerLink="/appointments">
			<mat-icon>arrow_back</mat-icon>
		</a>

		<h2>{{ patient.first_name }} {{ patient.last_name }}</h2>

		<span class="time" [matTooltip]="appointment.appointment_date | date : 'medium'">
			{{ appointment.appointment_date | date : "shortTime" }}
		</span>
	</div>

	<div class="bottom">
		<div>{{ patient.mrn }}</div>
		<div>{{ patient.birthdate | date : 'MM/dd/yyyy' }}</div>
		<div>{{ ageAndSex }}</div>

		@if (appointment.ehr_appointment_type_name) {
			<div>{{ appointment.ehr_appointment_type_name }}</div>
		}
	</div>

</div>

<div class="content">
	@switch (state) {
		@case (TranscriptionState.Transcribed) {
			@if (transcription) {
				<app-transcription [transcription]="transcription" [recording]="recording"></app-transcription>
			} @else {
				<p>All done for now, nothing left to do</p>
			}
		}
		@case (TranscriptionState.Transcription) {
			<div class="info">
				<p>Recording being processed by server, this normally takes a few minutes, check back later.</p>

				<div>
					<a mat-flat-button color="primary" routerLink="/appointments">Return to Appointments</a>
				</div>
			</div>
		}
		@case (TranscriptionState.Errored) {
			<mat-error>There was an error transcribing and summarizing the patient.</mat-error>
		}
		@default {
			<app-record [appointment]="appointment"
			            [previousRecording]="recording"
			            (onRecordingUpdated)="onRecordingUpdated($event)"></app-record>
		}
	}
</div>
