import {Component} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatDivider} from "@angular/material/divider";
import {SessionService} from "../../services/session.service";
import { UserType } from '@allaihealth/serverless-common';

/** Header of the site */
@Component({
	selector: 'app-header',
	standalone: true,
	imports: [
		MatButtonModule,
		RouterLink,
		MatMenuModule,
		MatIconModule,
		MatDivider
	],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent {
	constructor(private router:Router,
	            private sessionService:SessionService) {
	}

	/** True if this is not the login page */
	notLogin() {
		return !this.router.url.startsWith("/login")
	}

	/** Logout the user and return to the login page */
	async logout() {
		await this.sessionService.logout();
	}

	/** Is this an administrative user? */
	get isAdmin() {
		return this.sessionService.allaiUser?.user_type == UserType.Admin;
	}
}
