<a mat-ripple [routerLink]="prevLink" [class.disabled]="previous==null">
	<mat-icon>chevron_left</mat-icon>
	<span>Prev</span>

	@if (previous) {
		<span class="time">{{ previous.appointment_date | date : "shortTime" }}</span>
	}
</a>

<a mat-ripple [routerLink]="nextLink" [class.disabled]="next==null">
	@if (next) {
		<span class="time">{{ next.appointment_date | date : "shortTime" }}</span>
	}
	<span>Next</span>
	<mat-icon>chevron_right</mat-icon>
</a>
