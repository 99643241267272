import {inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {firstValueFrom, from, lastValueFrom, Observable, Subject} from "rxjs";
import {Amplify} from "aws-amplify";
import {AuthSession, AuthUser, fetchAuthSession, getCurrentUser, signIn, signOut} from 'aws-amplify/auth'
import {User} from '@allaihealth/serverless-common';

/** Handle authorization and authentication with Cognito and the backend */
@Injectable({
	providedIn: 'root'
})
export class SessionService implements HttpInterceptor {
	/** Stored redirection for the user to go to after they log in */
	private initialUrl:string[];

	/** The current Cognito user */
	private user:AuthUser;

	/** The current Cognito session */
	private session:AuthSession;

	/** The user loaded from the server with more information */
	private self:User;

	/** Event dispatched on logout */
	private onLogoutSubject:Subject<void> = new Subject();

	constructor(private router:Router,
	            private http:HttpClient) {
		//get the initial url the user wanted to go to
		this.initialUrl = window.location.pathname.split('/').filter(value => value.length > 0);
		if(this.initialUrl[0] == "login" || this.initialUrl.length == 0) {
			this.initialUrl = undefined;
		}

		//configure the login routine
		Amplify.configure({
			Auth: {
				Cognito: {
					userPoolId: environment.cognitoUserPoolId,
					userPoolClientId: environment.cognitoClientId
				}
			}
		});
	}

	/** Login the user */
	public async login(email:string, password:string) {
		await signOut();

		const response = await signIn({
			username: email,
			password
		});

		return response.isSignedIn;
	}

	/** Get a stored session from local storage */
	private async getSession() {
		this.user = await getCurrentUser();
		this.session = await fetchAuthSession();

		if(!this.user || !this.session) {
			throw new Error("No user returned");
		}
	}

	/** Is the user logged in? Or has a stored session */
	private async canActivate() {
		try {
			await this.getSession();

			//if no self loaded yet, load it
			if(!this.self) {
				this.self = await firstValueFrom(this.http.get<User>(`${environment.apiUrl}/self`));
			}

			return true;
		} catch(e) {
			return this.router.createUrlTree(["login"]);
		}
	}

	/** Logout the current user and redirect to log in */
	async logout() {
		await signOut();

		this.self = undefined;
		this.session = undefined;
		this.user = undefined;

		this.onLogoutSubject.next();

		//nav to the login page
		await this.router.navigate(['login']);
	}

	/** Intercept all REST calls and make sure a bearer is added if it is to the backend API */
	intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
		//if an api request
		if(request.url.startsWith(environment.apiUrl)) {
			return from(this.handleApiRequest(request, next));
		}

		return next.handle(request);
	}

	/** Handle an API request and make sure we have a fresh JWT */
	private async handleApiRequest(request:HttpRequest<any>, next:HttpHandler) {
		//make sure the jwt is fresh
		await this.getSession();

		const authorizedRequest:HttpRequest<any> = request.clone({
			setHeaders: {
				Authorization: 'Bearer ' + this.session.tokens.idToken.toString()
			}
		});

		return lastValueFrom(next.handle(authorizedRequest));
	}

	/** Redirect to the initial url that the user wanted to go to after login */
	redirectAfterLogin() {
		const initial = this.initialUrl ?? ["/"];
		this.initialUrl = undefined;
		return this.router.navigate(initial);
	}

	/** Return the user information of the current user */
	get allaiUser() {
		return this.self;
	}

	/** Event dispatched on logout */
	get onLogout() {
		return this.onLogoutSubject.asObservable();
	}

	/** Can a logged in page be activated or do they need to log in? */
	public static canActivateLoggedIn() {
		return inject(SessionService).canActivate();
	}
}
