<div class="controls">
	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" #input >
	</mat-form-field>

	<button mat-flat-button color="warning" [disabled]="selection.isEmpty()" (click)="onDelete()">
		<mat-icon>delete</mat-icon>
		Delete
	</button>
</div>

<div class="table-container">
	<table mat-table [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="selected">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? toggleAllRows() : null"
				              [checked]="selection.hasValue() && isAllSelected"
				              [indeterminate]="selection.hasValue() && !isAllSelected">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
				              (change)="$event ? selection.toggle(row) : null"
				              [checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container matColumnDef="time">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
			<td mat-cell *matCellDef="let row"> {{ row.time | date : 'short' }}</td>
		</ng-container>

		<ng-container matColumnDef="patient">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
			<td mat-cell *matCellDef="let row"> {{ row.patient }}</td>
		</ng-container>

		<ng-container matColumnDef="provider">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
			<td mat-cell *matCellDef="let row"> {{ row.provider }}</td>
		</ng-container>

		<ng-container matColumnDef="state">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
			<td mat-cell *matCellDef="let row">
				<app-state-pill [state]="row.state"></app-state-pill>
			</td>
		</ng-container>

		<ng-container matColumnDef="elapsed">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Elapsed</th>
			<td mat-cell *matCellDef="let row"> {{ row.elapsed }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goto(row)"></tr>

		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row" *matNoDataRow>
			@if (input.value) {
				<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
			} @else {
				<td class="mat-cell" colspan="4">No recordings</td>
			}
		</tr>
	</table>
</div>
