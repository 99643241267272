import {Component} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionService} from "../../services/session.service";
import {environment} from "../../../environments/environment";
import {NgOptimizedImage} from "@angular/common";
import {PageLoaderService} from "../../services/page-loader.service";
import {SnackbarService} from "../../services/snackbar.service";

/** Handle the login page */
@Component({
	selector: 'app-login',
	standalone: true,
	imports: [
		MatCardModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule,
		NgOptimizedImage
	],
	templateUrl: './login.component.html',
	styleUrl: './login.component.scss'
})
export class LoginComponent {
	readonly environment = environment;

	/** Login form */
	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', Validators.required)
	});

	/** Should the password be hidden? */
	hidePassword:boolean = true;

	constructor(private sessionService:SessionService,
	            private loaderService:PageLoaderService,
	            private snackbar:SnackbarService) {

	}

	/** Submit the login request */
	async submit() {
		if(this.form.valid) {
			try {
				const request = this.sessionService.login(this.form.controls.email.value,
					this.form.controls.password.value);

				this.loaderService.showAndListen(request, "Logging in...");
				await request;

				await this.sessionService.redirectAfterLogin();
			} catch(e) {
				console.log(e);
				this.snackbar.error("Failed to login", e);
			}
		}
	}
}
