import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {RecordComponent, RecordingStates} from "../record/record.component";
import {Duration} from "luxon";

/** Bar that shows the current recording state */
@Component({
  selector: 'app-recording-indicator',
  standalone: true,
	imports: [
		MatIcon
	],
  templateUrl: './recording-indicator.component.html',
  styleUrl: './recording-indicator.component.scss'
})
export class RecordingIndicatorComponent implements OnInit, OnDestroy {
	readonly RecordingStates = RecordingStates;

	/** Link to the recording component to get data from */
	@Input()
	recording:RecordComponent;

	/** The current duration */
	duration:string;

	/** Currently running interval to update the duration */
	private interval:any;

	ngOnInit() {
		//run an interval to update the duration, this needs to be an interval to avoid Angular change detection issues
		this.interval = setInterval(() => {
			const duration = Duration.fromMillis(this.recording.recordingTime);
			this.duration = duration.toFormat("mm:ss");
		}, 500);
	}

	ngOnDestroy() {
		if(this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	}
}
