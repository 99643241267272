import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {HttpClient} from "@angular/common/http";
import {PutAudioRequest, RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {firstValueFrom, Subscription} from "rxjs";
import {Appointment} from "@allaihealth/serverless-common";
import {environment} from "../../../../environments/environment";
import {PutAudioResponse} from "scribe-poc-shared/dist/util/put-audio-response";
import {PageLoaderService} from "../../../services/page-loader.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {Utils} from "../../../lib/utils";
import {RecordingService} from "../../../services/recording.service";
import {Duration} from "luxon";
import {DevicePickerComponent} from "./device-picker/device-picker.component";
import {MatError} from "@angular/material/form-field";

/** Handles the recording system and the state of the recording page */
@Component({
	selector: 'app-record',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		DevicePickerComponent,
		MatError
	],
	templateUrl: './record.component.html',
	styleUrl: './record.component.scss'
})
export class RecordComponent implements OnInit, OnDestroy {
	readonly RecordingStates = RecordingStates;

	/** The appointment this recording is for */
	@Input()
	appointment:Appointment;

	/** A previous recording that has already been made */
	private _previousRecording:RecordingInfo;

	/** The current state of the recording process */
	private _state:RecordingStates = RecordingStates.Initial;

	/** The final recording of the audio when done */
	private finalBlob:Blob;

	/** Subscription for when audio recording stops */
	private stopSubscription:Subscription;

	/** Subscription for when audio recording errors */
	private errorSubscription:Subscription;

	/** Event for when a recording is successfully uploaded to the server */
	@Output()
	onRecordingUpdated:EventEmitter<RecordingInfo> = new EventEmitter<RecordingInfo>();

	/** Event for when the recording state changes */
	@Output()
	onRecordingStateChange:EventEmitter<RecordingStates> = new EventEmitter<RecordingStates>();

	constructor(private changeDetectorRef:ChangeDetectorRef,
	            private recording:RecordingService,
	            private http:HttpClient,
	            private pageLoaderService:PageLoaderService,
	            private snackbar:SnackbarService) {
	}

	async ngOnInit() {
		if(await this.recording.testSupport()) {
			if(this.recording.devices.length == 0) {
				this.state = RecordingStates.NoDevices;
			} else {
				this.state = this._previousRecording ? RecordingStates.Done : RecordingStates.Waiting;
			}
		} else {
			this.state = RecordingStates.Unsupported;
		}
	}

	ngOnDestroy():void {
		this.recording.stopMic();
	}

	/** Called when the user uploads a file to the input element on the page */
	fileInputChange($event:Event) {
		const files = ($event?.target as HTMLInputElement)?.files;

		//set the final blob if a file was uploaded
		if(files instanceof FileList && files.length > 0 && files[0] instanceof Blob) {
			this.setFinalBlob(files[0]);
			this.uploadAudioPart().then();
		} else {
			console.error("Failed to read files from file event");
			this.snackbar.error("Failed to read files");
		}
	}

	/** Start recording the user */
	async startMic() {
		if(await this.recording.startMic()) {
			this.state = RecordingStates.Recording;

			this.stopSubscription = this.recording.onStopEvent.subscribe(blob => this.onStop(blob));
			this.errorSubscription = this.recording.onErrorEvent.subscribe(() => this.onError());
		} else {
			this.state = RecordingStates.Error;
		}
	}

	private onError() {
		this.state = RecordingStates.Error;
		this.stopSubscription.unsubscribe();
		this.errorSubscription.unsubscribe();
	}

	/** Stop recording and close the stream */
	stopMic() {
		//stop recording, the final blob will be saved once the onstop event is called
		this.recording.stopMic();
		this.pageLoaderService.show("Uploading recording...");
	}

	/** Called when the mic officially stops recording */
	private async onStop(blob:Blob) {
		this.stopSubscription.unsubscribe();
		this.errorSubscription.unsubscribe();

		//set the final blob of the audio
		this.setFinalBlob(blob);

		await this.uploadAudioPart(this.recording.recordingTime / 1000);
	}

	/** Set the final audio blob of the recording */
	private setFinalBlob(blob:Blob) {
		this.finalBlob = blob;

		//set the state
		this.state = RecordingStates.Done;
	}

	/**
	 * Upload a new audio part to the server
	 * @param duration the final audio duration in seconds
	 */
	private async uploadAudioPart(duration:number = 0) {
		try {
			const finalDuration = duration ?? await Utils.getAudioBlobDuration(this.finalBlob);

			//create the request
			const data:PutAudioRequest = {
				appointmentId: this.appointment.id,
				mimeType: this.finalBlob?.type ?? this.recording.preferredMime,
				audioLength: finalDuration
			};

			//initiate the upload and get a signed url for the upload
			const response =
				await firstValueFrom(this.http.put<PutAudioResponse>(`${environment.apiUrl}/recording/add`, data));

			this._previousRecording = response;

			//put the audio file to the signed url
			await firstValueFrom(this.http.put(response.signedUploadUrl, this.finalBlob));

			//trigger a page refresh (angular won't pick this up all the time)
			this.changeDetectorRef.detectChanges();

			this.onRecordingUpdated.next(this._previousRecording);
		} catch(e) {
			console.error("Failed to upload audio file", e);
			this.snackbar.error("Failed to upload the audio file", e);
		} finally {
			this.pageLoaderService.close();
		}
	}

	/** Upload the audio file for transcription */
	async upload() {
		const promise = firstValueFrom(this.http.post<PutAudioResponse>(
			`${environment.apiUrl}/recording/end/${this._previousRecording.appointmentId}`, {}));

		this.pageLoaderService.showAndListen(promise, "Ending appointment...");

		await promise;

		this._previousRecording.state = TranscriptionState.Transcription;

		this.onRecordingUpdated.next(this._previousRecording);
	}

	/** Get the current state */
	get state() {
		return this._state;
	}

	/** Set the current state */
	set state(value:RecordingStates) {
		this._state = value;

		this.onRecordingStateChange.next(value);
	}

	/** Return the total time of the recording in milliseconds */
	get recordingTime():number {
		const prevTime = (this._previousRecording?.audioLength ?? 0) * 1000;
		const currentTime = this.recording.recordingTime ?? 0;

		//add the previous time to the current time
		return prevTime + currentTime;
	}

	/** Is the recording in a state where the appointment nav shouldn't be shown? */
	get isBlockingRecordingState() {
		switch(this.state) {
			case RecordingStates.Recording:
				return true;
			default:
				return false;
		}
	}

	/** A list of file extensions accepted for file uploads */
	get mimesAccepted() {
		return RecordingService.mimesAccepted;
	}

	/** The duration text for the resume button */
	get durationText() {
		const duration = (this._previousRecording?.audioLength ?? 0) * 1000;
		return Duration.fromMillis(duration).toFormat("mm:ss");
	}

	/** A previous recording that has already been made */
	get previousRecording() {
		return this._previousRecording;
	}

	/** A previous recording that has already been made */
	@Input()
	set previousRecording(value) {
		if(this._previousRecording?.appointmentId !== value?.appointmentId) {
			this._previousRecording = value;

			this.ngOnInit().then();
		}
	}
}

/** Recording state of the page */
export enum RecordingStates {
	/** Waiting to determine if mic supported */
	Initial,

	/** No audio devices found */
	NoDevices,

	/** Recording not supported */
	Unsupported,

	/** The user declined mic access */
	MicDeclined,

	/** Waiting for the user to either record or upload a file */
	Waiting,

	/** Unknown error with recording */
	Error,

	/** The user is currently recording audio */
	Recording,

	/** Done recording waiting to upload */
	Done
}
