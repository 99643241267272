import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {AppointmentResponse, AppointmentsResponse, RecordingInfo} from "scribe-poc-shared";
import {environment} from "../../environments/environment";
import {Appointment, Patient} from "@allaihealth/serverless-common";
import {DateTime} from "luxon";
import {ActivatedRouteSnapshot} from "@angular/router";
import {SnackbarService} from "./snackbar.service";
import {SessionService} from "./session.service";

/** Service that loads and caches all appointments in the appointment list page */
@Injectable({
	providedIn: 'root'
})
export class AppointmentsService {
	/** Loaded appointments by id */
	private appointments:Map<string, Appointment>;

	/** All appointments sorted by start time */
	private _sortedAppointments:Appointment[];

	/** loaded patients by their id */
	private patients:Map<string, Patient>;

	/** Loaded recordings by their id */
	private recordings:Map<string, RecordingInfo>;

	constructor(private http:HttpClient,
	            private sessionService:SessionService,
	            private snackbar:SnackbarService) {
		this.sessionService.onLogout.subscribe(() => {
			this.appointments = undefined;
			this._sortedAppointments = undefined
			this.patients = undefined;
			this.recordings = undefined;
		})
	}

	/** Get all appointments from the database */
	private async getAppointments() {
		try {
			//load from server
			const response = await firstValueFrom(
				this.http.get<AppointmentsResponse>(`${environment.apiUrl}/appointments`));

			this.appointments = new Map();

			//go through each appointment and add to map
			for(const appointment of response.appointments) {
				//set the date since dates can't be serialized in json
				appointment.appointment_date = new Date(appointment.appointment_date);

				this.appointments.set(appointment.id, appointment);
			}

			//sort the appointments
			this._sortedAppointments = response.appointments.sort((a, b) =>
				a.appointment_date.getTime() - b.appointment_date.getTime());

			//add the patients
			this.patients = new Map(response.patients.map(value => {
				value.birthdate = AppointmentsService.convertBirthDate(value.birthdate);
				return [value.id, value];
			}));

			//add the recordings
			this.recordings = new Map(response.recordings.map(value => [value.appointmentId, value]));
		} catch(e) {
			console.error("Failed to load appointments from server", e);
			this.snackbar.error("Failed to download appointments", e);
		}
	}

	/** Get a patient by id */
	getPatient(id:string) {
		return this.patients.get(id);
	}

	/** Get an appointment by id */
	getAppointment(id:string) {
		return this.appointments.get(id);
	}

	/** Get a recording by id */
	getRecording(id:string) {
		return this.recordings.get(id);
	}

	/**
	 * Find the previous and next appointment in the list from the given appointment's id
	 * @param id the id of the appointment to get the previous and next of
	 * @returns tuple of the previous and next appointment ([previous, next])
	 */
	findPreviousNext(id:string):[Appointment | undefined, Appointment | undefined] {
		const appointment = this.getAppointment(id);
		if(!appointment) {
			return [undefined, undefined];
		}

		//get the index
		const index = this.sortedAppointments.indexOf(appointment);
		if(index < 0) {
			return [undefined, undefined];
		}

		//get the next and previous
		const next = index + 1 < this.sortedAppointments.length ? this.sortedAppointments[index + 1] : undefined;
		const prev = index - 1 >= 0 ? this.sortedAppointments[index - 1] : undefined;
		return [prev, next];
	}

	/**
	 * Add a new recording to the service
	 * @param recording the new recording to add
	 */
	addRecording(recording:RecordingInfo) {
		this.recordings.set(recording.appointmentId, recording)
	}

	/** Clear out loaded appointments and force a reload */
	clear() {
		this.appointments = undefined;
		this._sortedAppointments = undefined;
		this.patients = undefined;
		this.recordings = undefined;
	}

	/** Get the sorted appointments */
	get sortedAppointments() {
		return this._sortedAppointments;
	}

	/** Resolver to get all appointments for the list if not already loaded */
	static async resolveAppointments():Promise<Appointment[]> {
		const service = inject(AppointmentsService);
		if(!service.sortedAppointments) {
			await service.getAppointments();
		}

		return service.sortedAppointments;
	}

	/** Resolver to a single appointment from the route id */
	static async resolveAppointment(route:ActivatedRouteSnapshot):Promise<AppointmentResponse> {
		const id = route.paramMap.get("id");

		const http = inject(HttpClient);

		//get it from the server
		const response =
			await firstValueFrom(http.get<AppointmentResponse>(`${environment.apiUrl}/list/${id}`));

		//normalize the birthdate
		response.patient.birthdate = AppointmentsService.convertBirthDate(response.patient.birthdate);

		return response;
	}

	/**
	 * Convert a birthdate to the current time zone from UTC
	 * @param date the date of the birthdate in utc
	 */
	static convertBirthDate(date:string | number | Date) {
		let dateTime:DateTime;
		if(typeof date === "string") {
			dateTime = DateTime.fromISO(date, {zone: "utc"});
		} else if(typeof date === "number") {
			dateTime = DateTime.fromMillis(date, {zone: "utc"});
		} else {
			dateTime = DateTime.fromJSDate(date);
		}

		return new Date(dateTime.get("year"), dateTime.get("month") - 1, dateTime.get("day"));
	}
}
