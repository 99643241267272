import {Appointment} from '@allaihealth/serverless-common';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {AppointmentResponse, RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {AppointmentsService} from "../../services/appointments.service";
import {AppointmentNavComponent} from "./appointment-nav/appointment-nav.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {DatePipe} from "@angular/common";
import {DateTime} from "luxon";
import {RecordComponent} from "./record/record.component";
import {RecordingIndicatorComponent} from "./recording-indicator/recording-indicator.component";
import {WaitingHeaderComponent} from "./waiting-header/waiting-header.component";
import {TranscriptionComponent} from "./transcription/transcription.component";
import {MatError} from "@angular/material/form-field";
import {MatTooltip} from "@angular/material/tooltip";

/** Show information on an appointment and any recordings that need to be done or are done */
@Component({
	selector: 'app-appointment',
	standalone: true,
	imports: [
		AppointmentNavComponent,
		MatButtonModule,
		MatIconModule,
		RouterLink,
		DatePipe,
		RecordComponent,
		RecordingIndicatorComponent,
		WaitingHeaderComponent,
		TranscriptionComponent,
		MatError,
		MatTooltip
	],
	templateUrl: './appointment.component.html',
	styleUrl: './appointment.component.scss'
})
export class AppointmentComponent implements OnInit {
	readonly TranscriptionState = TranscriptionState;

	/** Data from the backend on the appointment */
	private data:AppointmentResponse;

	/** The recording component on the page (can be null if not active) */
	private _recordingComponent:RecordComponent;

	/** The previous appointment for the user */
	previous:Appointment;

	/** The next appointment for the user */
	next:Appointment;

	constructor(private route:ActivatedRoute,
	            private appointmentsService:AppointmentsService) {
	}

	ngOnInit():void {
		//get the data from the route
		this.route.data.subscribe(value => {
			this.data = value?.["appointment"] as AppointmentResponse;

			[this.previous, this.next] = this.appointmentsService.findPreviousNext(this.data.appointment.id);
		});
	}

	/** Called when the user finishes recording */
	onRecordingUpdated(recordingInfo:RecordingInfo):void {
		this.data.recording = recordingInfo;

		this.appointmentsService.addRecording(recordingInfo);
	}

	/** Get the current patient */
	get patient() {
		return this.data.patient;
	}

	/** Get the current recording */
	get recording() {
		return this.data.recording;
	}

	/** Get the current transcription */
	get transcription() {
		return this.data.transcription;
	}

	/** Get the current appointment */
	get appointment() {
		return this.data.appointment;
	}

	/** Get the current recording state, or undefined if there is none */
	get state():TranscriptionState {
		return this.data.recording?.state;
	}

	/** Get the age and sex of the patient for the header */
	get ageAndSex() {
		const duration = DateTime.now().diff(DateTime.fromJSDate(this.patient.birthdate), ["years"]);

		let sex:string;
		switch(this.patient.sex?.toLowerCase()) {
			case "male":
				sex = "M";
				break;
			case "female":
				sex = "F";
				break;
			default:
				sex = "?";
				break;
		}

		return `${Math.floor(duration.get("years"))}${sex}`
	}

	/** Is this appointment currently being recorded into? */
	get isRecording():boolean {
		return (this.state == undefined || this.state == TranscriptionState.Recording) &&
			this.recordingComponent?.isBlockingRecordingState;
	}

	/** The recording component on the page (can be null if not active) */
	@ViewChild(RecordComponent)
	set recordingComponent(value:RecordComponent) {
		this._recordingComponent = value;
	}

	/** The recording component on the page (can be null if not active) */
	get recordingComponent() {
		return this._recordingComponent;
	}
}
