@if (recording && transcription) {
	<div class="top">
		<h3>Chief Complaint</h3>
		<app-summary [summary]="summaries.get('CHIEF_COMPLAINT')" (evidenceClicked)="onNewEvidence($event)"></app-summary>
	</div>

	<mat-tab-group>
		@for (tab of tabs; track tab) {
			<mat-tab>
				<ng-template mat-tab-label>
					<span matTooltip="{{tab.tooltip}}">{{tab.label}}</span>
				</ng-template>

				<app-summary [summary]="summaries.get(tab.name)" (evidenceClicked)="onNewEvidence($event)"></app-summary>
			</mat-tab>
		}

		<mat-tab>
			<ng-template mat-tab-label>
				<span matTooltip="Transcript">Tr</span>
			</ng-template>

			<app-transcript [transcript]="transcription.transcript" [evidence]="evidence"></app-transcript>
		</mat-tab>
	</mat-tab-group>
}
