<div class="logo">
	<img ngSrc="assets/full-logo.svg" alt="AllaiHealth Logo" height="68" width="129" priority />
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
	<mat-form-field>
		<input matInput placeholder="Email" formControlName="email" required type="email">
	</mat-form-field>

	<mat-form-field>
		<input formControlName="password" matInput placeholder="Password"
		       [type]="hidePassword ? 'password' : 'text'"
		       required>
		<mat-icon matSuffix
		          (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}
		</mat-icon>
	</mat-form-field>

	<div class="forgot">
		<a [href]="environment.mainUrl + '/forgot-password'" target="_blank">Forgot password</a>
	</div>

	<div class="buttons">
		<button color="primary" mat-flat-button [disabled]="!form.valid" aria-label="Press to login">
			Login
		</button>
	</div>
</form>
