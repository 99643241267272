import {Component, inject, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

/** Simple dialog to show yes/no questions */
@Component({
	selector: 'app-yes-no-dialog',
	standalone: true,
	imports: [
		MatDialogModule,
		MatButtonModule
	],
	templateUrl: './yes-no-dialog.component.html',
	styleUrl: './yes-no-dialog.component.scss'
})
export class YesNoDialogComponent implements OnInit {
	constructor(private dialogRef:MatDialogRef<boolean, YesNoDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public data:YesNoData) {
	}

	ngOnInit():void {
		if(this.data.ok) {
			this.data.yes = 'Ok'
			this.data.no = undefined;
		} else {
			this.data.yes = this.data.yes ?? 'Yes';
			this.data.no = this.data.no ?? 'No';
		}

		if(this.data.disableClose === undefined) {
			this.data.disableClose = !this.data.ok;
		}

		this.dialogRef.disableClose = this.data.disableClose ?? !this.data.ok;
		this.dialogRef.updateSize('500px');
	}
}

/** Data to pass to the dialog to open it */
export interface YesNoData {
	/** Optional title for the dialog */
	title?:string;

	/** Message to show to the user */
	message:string;

	/** No button text */
	no?:string;

	/** Yes button text */
	yes?:string;

	/** Set to true if this is just a one button dialog */
	ok?:boolean;

	/** Should this dialog disable the automatic close? Defaults to true if a yes/no dialog */
	disableClose?:boolean;
}
