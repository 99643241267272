<div class="title">
	<a class="logo" routerLink="./"></a>
</div>

@if (notLogin()) {
	<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Button for main menu">
		<mat-icon>menu</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<a mat-menu-item routerLink="/appointments">
			<mat-icon>today</mat-icon>
			<span>Appointments</span>
		</a>

		@if (isAdmin) {
			<a mat-menu-item routerLink="/list">
				<mat-icon>list</mat-icon>
				<span>List</span>
			</a>
		}

		<mat-divider></mat-divider>

		<button mat-menu-item (click)="logout()">
			<mat-icon>logout</mat-icon>
			<span>Logout</span>
		</button>
	</mat-menu>
}

