import {Component, OnInit} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {TranscriptionState} from 'scribe-poc-shared';
import {AppointmentsService} from "../../services/appointments.service";
import {StatePillComponent} from "../../components/state-pill/state-pill.component";

/** The appointments list page */
@Component({
	selector: 'app-appointments',
	standalone: true,
	imports: [
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		DatePipe,
		StatePillComponent
	],
	templateUrl: './appointments.component.html',
	styleUrl: './appointments.component.scss'
})
export class AppointmentsComponent implements OnInit {
	readonly TranscriptionState = TranscriptionState;

	/** Columns in the table */
	readonly displayedColumns:string[] = ['time', 'patient', 'info'];

	/** Data source for the table */
	dataSource:MatTableDataSource<AppointmentRow>

	constructor(private appointmentsService:AppointmentsService,
	            private router:Router) {
	}

	ngOnInit() {
		//get the appointments loaded
		const appointments = this.appointmentsService.sortedAppointments;

		//convert the appointments into data for the list view
		const rows:AppointmentRow[] = appointments.map(value => {
			const patient = this.appointmentsService.getPatient(value.id_patient);
			const recording = this.appointmentsService.getRecording(value.id);

			return <AppointmentRow>{
				appointmentId: value.id,
				firstName: patient.first_name,
				lastName: patient.last_name,
				dob: patient.birthdate,
				appointmentTime: value.appointment_date,
				appointmentType: value.ehr_appointment_type_name,
				state: recording?.state
			};
		});

		this.dataSource = new MatTableDataSource<AppointmentRow>(rows);
	}

	/** Apply a filter from the search bar */
	applyFilter(event:Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if(this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/** Open an appointment that was clicked on */
	goto(row:AppointmentRow) {
		this.router.navigate(['/', 'appointments', row.appointmentId]);
	}

	/** Is the appointment a future appointment? */
	isCurrent(row:AppointmentRow) {
		return new Date() < new Date(row.appointmentTime)
	}
}

/** An appointment row to show in the list */
interface AppointmentRow {
	/** The id of the appointment */
	appointmentId:string;

	/** The first name of the patient */
	firstName:string;

	/** The last name of the patient */
	lastName:string;

	/** The date of birth of the patient */
	dob:number | Date;

	/** The time the appointment starts */
	appointmentTime:number | Date;

	/** The state of the recording transcription */
	state:TranscriptionState;

	/** The type of the apppointment from the ehr */
	appointmentType?:string;
}
