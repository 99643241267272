"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscriptionState = void 0;
/** The state of transcription of an audio recording */
var TranscriptionState;
(function (TranscriptionState) {
    /** The user is recording audio */
    TranscriptionState["Recording"] = "recording";
    /** Waiting for the transcription to be made by AWS */
    TranscriptionState["Transcription"] = "transcription";
    /** The audio file has finished being transcribed */
    TranscriptionState["Transcribed"] = "transcribed";
    /** There was an error transcribing the audio */
    TranscriptionState["Errored"] = "errored";
})(TranscriptionState || (exports.TranscriptionState = TranscriptionState = {}));
